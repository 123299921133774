import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { CirclePicker } from 'react-color'
import Select from 'react-select'
import Banner from '../../components/Banner'
import BreadCrumbBlock from '../../components/Breadcrumb'
import FeaturedProductItem from '../../components/FeaturedProduct/FeaturedProductItem'
import Layout from '../../components/Layout'
import Section from '../../components/Layout/Section'
import Title from '../../components/Layout/Title'
import Tile from '../../components/Tile'

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  menu: base => ({
    ...base,
    width: 'max-content',
    zIndex: 10,
  }),
  container: provided => ({
    ...provided,
    minWidth: 125,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? 'rgba(0, 0, 0, 0.5)'
      : isSelected
      ? 'rgba(0, 0, 0, 1)'
      : 'rgba(0, 0, 0, 0)',
    color: isFocused ? '#ffffff' : isSelected ? '#ffffff' : undefined,
  }),
}

const options = [
  { value: 'most-popular', label: 'Most popular' },
  { value: 'position', label: 'Position' },
  { value: 'low-to-high', label: 'Price Low to High' },
  { value: 'high-to-low', label: 'Price High to Low' },
]

// markup
const Product = () => {
  const [filterToggle, setFilterToggle] = useState(false)
  const [hex, setHex] = useState('#4F73F1')

  return (
    <Layout>
      <Banner />
      <BreadCrumbBlock />
      <Section noSpacingTop hasDivider>
        <Title title="Resources" className="text-center" />
        <Container>
          <div className="filter">
            <div className="filter-mega">
              <button
                className="btn-toggle btn-reset"
                onClick={() =>
                  setFilterToggle(prevFilterState => !prevFilterState)
                }
              >
                Filters
              </button>
            </div>
            <div className={`mega-filter${filterToggle ? ' show' : ''}`}>
              <Container>
                <div className="category-wrap">
                  <h5>CATEGORY</h5>
                  <div className="category-wrap-inner">
                    <span className="item">Furniture</span>
                    <span className="item active">Resources</span>
                    <span className="item">Consumables </span>
                    <span className="item">Outdoors</span>
                    <span className="item">Art & Craft</span>
                    <span className="item">Gross Motor & Sensory</span>
                    <span className="item">Storage</span>
                    <span className="item">Staging & Presentations</span>
                    <span className="item">Dramatic Play</span>
                    <span className="item">Collectios & Bundles</span>
                    <span className="item">Office & Administration</span>
                  </div>
                </div>
                <hr className="divider" />
                <div className="category-wrap-custom">
                  <p className="h5">FILTERS</p>
                  <Row>
                    <Col xs={12} lg className="mb-3 mb-lg-0">
                      <p className="h5">Sector</p>
                      <Form.Check
                        type="checkbox"
                        id="checkbox1"
                        label="Early Education"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox2"
                        label="Primary Education"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox3"
                        label="Secondary Education"
                        className="mb-2 mb-lg-3"
                      />
                    </Col>
                    <Col xs={12} lg className="mb-3 mb-lg-0">
                      <p className="h5">Room</p>
                      <Form.Check
                        type="checkbox"
                        id="checkbox4"
                        label="Change Room"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox5"
                        label="Sleep Room"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox6"
                        label="Play Room"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox7"
                        label="Classroom"
                        className="mb-2 mb-lg-3"
                      />
                      <button className="btn-reset">More +</button>
                    </Col>
                    <Col xs={12} lg className="mb-3 mb-lg-0">
                      <p className="h5">Brand</p>
                      <Form.Check
                        type="checkbox"
                        id="checkbox8"
                        label="Grocare"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox9"
                        label="Haba"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox10"
                        label="Outdorable"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox11"
                        label="Grospace"
                        className="mb-2 mb-lg-3"
                      />
                      <button className="btn-reset">More +</button>
                    </Col>
                    <Col xs={12} lg={'auto'} className="mb-3 mb-lg-0">
                      <p className="h5">Price</p>
                      <div className="price-group">
                        <div className="price-box">
                          <label>Min Price</label>
                          <div className="price-box-inner">
                            <span>$</span>
                            <input type="number" />
                          </div>
                        </div>
                        <div className="spacer"></div>
                        <div className="price-box">
                          <label>Max Price</label>
                          <div className="price-box-inner">
                            <span>$</span>
                            <input type="number" />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} lg={1} className="mb-3 mb-lg-0">
                      <p className="h5">Color</p>
                      <CirclePicker
                        colors={[
                          '#4F73F1',
                          '#DBDBDB',
                          '#FFD337',
                          '#B4EEA5',
                          '#75DFCC',
                        ]}
                        color={hex}
                        onChange={color => {
                          setHex(color.hex)
                        }}
                      />
                    </Col>
                    <Col xs={12} lg className="mb-3 mb-lg-0">
                      <p className="h5">Ages</p>
                      <Form.Check
                        type="checkbox"
                        id="checkbox12"
                        label="Ages 1-2"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox13"
                        label="Ages 2-3"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox14"
                        label="Ages 3-4"
                        className="mb-2 mb-lg-3"
                      />
                      <Form.Check
                        type="checkbox"
                        id="checkbox15"
                        label="Ages 4-5"
                        className="mb-2 mb-lg-3"
                      />
                    </Col>
                  </Row>
                  <button className="btn-clear btn-reset">CLEAR ALL</button>
                </div>
              </Container>
            </div>
            <div className="filter-select">
              <p className="h5">SORT BY</p>
              <Select
                defaultValue={{ value: 'most-popular', label: 'Most popular' }}
                isSearchable={false}
                styles={customStyles}
                options={options}
              />
            </div>
          </div>
          <Row className="product-list">
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
            <FeaturedProductItem />
          </Row>
          <button className="btn btn-outline-primary mx-auto d-flex">
            Show More Products
          </button>
        </Container>
      </Section>
      {/*<Section>*/}
      {/*  <Tile />*/}
      {/*</Section>*/}
    </Layout>
  )
}

export default Product
