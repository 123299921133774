import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../Layout/Title";
import PlaceholderImage from "../../images/tile-1.png";

// markup
const Tile = () => {
  return (
    <Container>
      <Title className="text-center" title="Tile Block"></Title>
      <Row className="tile-wrap">
        <Col xs={12} lg={4}>
          <a href="" className="tile-item">
            <div className="tile-img">
              <img src={PlaceholderImage} alt="" />
            </div>
            <h4 className="cta">Dramatic Play</h4>
          </a>
        </Col>
        <Col xs={12} lg={4}>
          <a href="" className="tile-item">
            <div className="tile-img">
              <img src={PlaceholderImage} alt="" />
            </div>
            <h4 className="cta">Storage</h4>
          </a>
        </Col>
        <Col xs={12} lg={4}>
          <a href="" className="tile-item">
            <div className="tile-img">
              <img src={PlaceholderImage} alt="" />
            </div>
            <h4 className="cta">Consumables</h4>
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Tile;
