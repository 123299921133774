import React from "react";
import PropTypes from "prop-types";
import DefaultBanner from "../../images/banner.png";
import { Container } from "react-bootstrap";

const Banner = ({ title, imageURL }) => {
  return (
    <div className="banner">
      <img
        className="banner-image"
        alt=""
        src={imageURL ? imageURL : DefaultBanner}
      />
      {title && (
        <Container>
          <h1 className="h2">{title}</h1>
        </Container>
      )}
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.string,
  imageURL: PropTypes.string,
};

export default Banner;
